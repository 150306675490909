import React from 'react'
import PropTypes from 'prop-types'
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import { IconExpandMoreDefault } from '../Icons'

export interface ItemProps extends React.ComponentProps<typeof AccordionItem> {
  label: React.ReactElement | string
  headingAriaLevel?: number
  headingClassName?: string
  buttonClassName?: string
  itemPanelClassName?: string
  iconClassName?: string
  expandButtonSize?: 'sm' | 'md' | 'lg' | 'scale-8' | 'scale-10' | number
}

const Item = ({
  uuid,
  className = '',
  label,
  children,
  headingAriaLevel,
  headingClassName,
  buttonClassName,
  itemPanelClassName,
  iconClassName,
  expandButtonSize = 'md',
  ...props
}: ItemProps) => (
  <AccordionItem
    uuid={uuid}
    className={`mc-accordion__item ${className}`}
    {...props}
  >
    <AccordionItemHeading
      aria-level={headingAriaLevel}
      className={headingClassName}
    >
      <AccordionItemButton
        className={`mc-accordion__button mc-p-4 mc-mb-1 ${buttonClassName}`}
      >
        {label}
        <IconExpandMoreDefault
          size={expandButtonSize}
          className={`mc-ml-5 mc-accordion__icon ${iconClassName}`}
        />
      </AccordionItemButton>
    </AccordionItemHeading>
    <AccordionItemPanel>
      <div className={`mc-p-4 mc-pt-0 mc-mb-1 ${itemPanelClassName}`}>
        {children}
      </div>
    </AccordionItemPanel>
  </AccordionItem>
)

Item.propTypes = {
  uuid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  label: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  headingAriaLevel: PropTypes.number,
}

export default Item

import React from 'react'
import Link from 'next/link'

import { IconLockDefault, IconPrivacyOptionsDefault } from '@mc/design-system'
import { useTranslation } from '@mc/i18n'

import { usePersonaEvents } from '../../events'
import {
  AI_TERMS_HREF,
  CALIFORNIA_PRIVACY_NOTICE_HREF,
  DO_NOT_SELL_MY_PERSONAL_INFO_HREF,
  PRIVACY_POLICY_HREF,
  TERMS_OF_SERVICE_HREF,
  SUPPORT_HREF,
} from '../../utils/legal'
import { TrackingSectionContainer } from '../TrackingSectionContainer'

const ROW_ANALYTICS = {
  rowName: 'tos',
  // This is the position for only the landing page
  rowPositionIndex: 10,
}

interface FooterProps {
  className?: string
  rowAnalytics?: {
    rowName: string
    rowPositionIndex: number
  }
}

export const Footer = ({
  className = '',
  rowAnalytics = ROW_ANALYTICS,
}: FooterProps) => {
  const { t } = useTranslation('@mc/persona')
  const { trackPersonaSignClick } = usePersonaEvents()

  const LINKS = [
    {
      title: t('landing.footer.builtWithLlama'),
    },
    {
      title: t('landing.footer.privacyPolicy'),
      href: PRIVACY_POLICY_HREF,
    },
    {
      title: t('landing.footer.caPrivacyPolicy'),
      href: CALIFORNIA_PRIVACY_NOTICE_HREF,
    },
    {
      title: t('landing.footer.doNotSellMyPersonalInfo'),
      href: DO_NOT_SELL_MY_PERSONAL_INFO_HREF,
      showIcon: true,
    },
    {
      title: t('landing.footer.terms'),
      href: TERMS_OF_SERVICE_HREF,
    },
    {
      title: t('landing.footer.aiTerms'),
      href: AI_TERMS_HREF,
    },
    {
      title: t('landing.footer.support'),
      href: SUPPORT_HREF,
    },
  ]

  return (
    <TrackingSectionContainer analytics={rowAnalytics}>
      <div
        className={`uncontainer d-flex flex-lg-row flex-column mc-p-8 mc-text-small mc-text--center mc-text-lg--left mc-mt-8 position-relative ${className}`}
      >
        <div className='d-flex mc-mx-auto mc-mx-lg-4'>
          <IconLockDefault size='sm' className='mc-pt-1 mc-mr-1' />
          <p className='mc-text-color--tint'>
            {t('landing.footer.securedWithSsl')}
          </p>
        </div>
        {LINKS.map((link, index) => {
          const WrapperComp: React.ElementType = link.href ? Link : 'span'
          return (
            <WrapperComp
              key={link.title}
              {...(typeof WrapperComp !== 'string' && {
                href: link.href,
                onClick: () =>
                  trackPersonaSignClick({
                    cta: link.title,
                    location: 'tos',
                  }),
              })}
              className={`d-flex justify-content-center align-items-center mc-mr-lg-4 mc-mt-4 mc-mt-lg-0 ${
                index === 0 ? 'mc-ml-lg-auto' : ''
              }`}
            >
              {link.title}
              {link.showIcon && (
                <IconPrivacyOptionsDefault
                  size='lg'
                  className='mc-overflow--visible mc-ml-1'
                  viewBox='0 0 30 15'
                  style={{ width: '30px', height: '15px' }}
                />
              )}
            </WrapperComp>
          )
        })}
      </div>
    </TrackingSectionContainer>
  )
}
